import React from 'react'
import { Link } from 'react-router-dom'

import PropTypes from 'prop-types'

import './header.css'

const Header = (props) => {
  return (
    <div className="header-header">
      <header data-thq="thq-navbar" className="header-navbar-interactive">
        <span className="header-logo">{props.Logo}</span>
        <div data-thq="thq-navbar-nav" className="header-desktop-menu">
          <nav className="header-links">
            <Link to="/" className="header-nav1">
              {props.Nav1}
            </Link>
            <Link to="/om-census-ip" className="header-nav2">
              {props.Nav2}
            </Link>
            <Link to="/kontakt" className="header-nav3">
              {props.Nav3}
            </Link>
          </nav>
        </div>
        <div data-thq="thq-burger-menu" className="header-burger-menu">
          <svg viewBox="0 0 1024 1024" className="header-icon">
            <path d="M128 554.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 298.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 810.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"></path>
          </svg>
        </div>
        <div data-thq="thq-mobile-menu" className="header-mobile-menu">
          <div className="header-nav">
            <div className="header-top">
              <span className="header-logo1">{props.Logo1}</span>
              <div data-thq="thq-close-menu" className="header-close-menu">
                <svg viewBox="0 0 1024 1024" className="header-icon2">
                  <path d="M810 274l-238 238 238 238-60 60-238-238-238 238-60-60 238-238-238-238 60-60 238 238 238-238z"></path>
                </svg>
              </div>
            </div>
            <nav className="header-links1">
              <Link to="/" className="header-nav11">
                {props.Nav11}
              </Link>
              <Link to="/om-census-ip" className="header-nav21">
                {props.Nav21}
              </Link>
              <Link to="/kontakt" className="header-nav31">
                {props.Nav31}
              </Link>
            </nav>
            <div className="header-buttons"></div>
          </div>
        </div>
      </header>
    </div>
  )
}

Header.defaultProps = {
  Nav1: 'Hjem',
  Logo: 'CENSUS IP',
  Nav21: 'Om Census IP',
  Nav31: 'Kontakt',
  Nav4: 'Team',
  Nav5: 'Blog',
  Nav11: 'Hjem',
  Nav2: 'Om Census IP',
  Logo1: 'CENSUS IP',
  Nav3: 'Kontakt',
  Register: 'Register',
  Login: 'Login',
}

Header.propTypes = {
  Nav1: PropTypes.string,
  Logo: PropTypes.string,
  Nav21: PropTypes.string,
  Nav31: PropTypes.string,
  Nav4: PropTypes.string,
  Nav5: PropTypes.string,
  Nav11: PropTypes.string,
  Nav2: PropTypes.string,
  Logo1: PropTypes.string,
  Nav3: PropTypes.string,
  Register: PropTypes.string,
  Login: PropTypes.string,
}

export default Header

import React from 'react'

import { Helmet } from 'react-helmet'

import Header from '../components/header'
import FeatureCard from '../components/feature-card'
import './home.css'

const Home = (props) => {
  return (
    <div className="home-container">
      <Helmet>
        <title>Census IP</title>
        <meta property="og:title" content="Quiet Lone Clam" />
      </Helmet>
      <Header></Header>
      <div className="home-hero">
        <div className="home-hero1">
          <div className="home-container1">
            <h1 className="home-hero-heading heading1">
              Census IP er et selskap som hjelper til med  håndtering av
              immaterielle verdier, og spesielt patent.
            </h1>
            <div className="home-btn-group"></div>
          </div>
          <div className="home-container2"></div>
          <div className="home-container3">
            <span className="home-text">
              <span>
                Vår rådgiver Jan-Ove Hindenes har mer enn 25 års erfaring fra
                bransjen, og kan yte bistand i alt patentarbeid. Vi kan
                utarbeide og innlevere patentsøknader, i Norge og alle utland,
                og vi kan håndtere saksbehandlingen ved de forskjellige
                patentverk frem til godkjennelse av patent.
              </span>
              <br></br>
              <br></br>
              <br></br>
              <span>
                Vi arbeider først og fremst innen teknologiområdene biologi,
                kjemi, biokjemi, bioteknolog, farmasi, ernæring og akvakultur.
              </span>
              <br></br>
            </span>
          </div>
        </div>
      </div>
      <div className="home-features">
        <div className="home-container4">
          <h2 className="home-features-heading heading2">Våre tjenester</h2>
        </div>
        <div className="home-container5">
          <FeatureCard
            Heading="Rådgiving"
            SubHeading="Profesjonell rådgivning innen patentarbeid"
          ></FeatureCard>
          <FeatureCard
            Heading="Forundersøkelser"
            SubHeading="Gjennomføring av grundige undersøkelser før patentsøknader"
          ></FeatureCard>
          <FeatureCard
            Heading="Patentsøknader og saksbehandling"
            SubHeading="Utarbeidelse og innlevering av patentsøknader, samt håndtering av saksbehandlingen"
          ></FeatureCard>
          <FeatureCard
            Heading="FtO"
            SubHeading="Vurdering av handlingsrom og muligheter innen patentområdet"
          ></FeatureCard>
          <FeatureCard
            Heading="Due dilligence patentporteføljer"
            SubHeading="Vurdering av handlingsrom og muligheter innen patentområdet"
          ></FeatureCard>
          <FeatureCard
            Heading="In-house patentrådgivning"
            SubHeading="Vurdering av handlingsrom og muligheter innen patentområdet"
          ></FeatureCard>
        </div>
      </div>
    </div>
  )
}

export default Home

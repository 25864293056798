import React from 'react'

import { Helmet } from 'react-helmet'

import Header from '../components/header'
import './om-census-ip.css'

const OmCensusIp = (props) => {
  return (
    <div className="om-census-ip-container">
      <Helmet>
        <title>Om - Census IP</title>
        <meta property="og:title" content="om-census-ip - Quiet Lone Clam" />
      </Helmet>
      <Header></Header>
      <div className="om-census-ip-hero">
        <div className="om-census-ip-hero1">
          <div className="om-census-ip-container1">
            <h2 className="om-census-ip-details-heading heading2">
              Om Census IP
            </h2>
            <span className="om-census-ip-text">
              <span>
                Census AS ble startet I 2021 etter at jeg sa opp mine
                arbeidsforhold i Acapo og Actio IP. Jeg ble kontaktet av
                tidligere kunder som ønsket at jeg skulle håndtere
                patentporteføljen deres videre. Jan-Ove Hindenes er eneste
                ansatte i Census IP.
              </span>
              <br></br>
              <br></br>
              <span>Jan-Ove Hindenes</span>
              <br></br>
              <span>-</span>
              <span> Cand. Scient fra Universitetet i Bergen</span>
              <br></br>
              <span>- Europeisk patentfullmektig</span>
              <br></br>
              <span>
                - Medlem av FONIP (Foreningen for norske IP-rådgivere), FICPI og
                EPI
              </span>
              <br></br>
              <span>
                - Mer enn 25 års erfaring i patentarbeid fra Acapo, og var i
                perioder daglig leder og leder for patentavdelingen
              </span>
              <br></br>
              <span>- Daglig leder i Actio IP fra 2009</span>
            </span>
          </div>
        </div>
      </div>
    </div>
  )
}

export default OmCensusIp

import React from 'react'

import { Helmet } from 'react-helmet'

import Header from '../components/header'
import './kontakt.css'

const Kontakt = (props) => {
  return (
    <div className="kontakt-container">
      <Helmet>
        <title>Kontakt - Census IP</title>
        <meta property="og:title" content="kontakt - Quiet Lone Clam" />
      </Helmet>
      <Header></Header>
      <div className="kontakt-hero">
        <div className="kontakt-hero1">
          <div className="kontakt-container1">
            <h2 className="kontakt-details-heading heading2">Kontakt</h2>
            <span className="kontakt-text">
              <span>Send meg en e-post på janove.hindenes@censusip.no</span>
              <br></br>
              <br></br>
              <span>Census AS</span>
              <br></br>
              <span>Sandviksbodene 73a</span>
              <br></br>
              <span>5035 Bergen</span>
              <br></br>
              <br></br>
              <span>Org nummer: 926 865 137</span>
              <br></br>
            </span>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Kontakt
